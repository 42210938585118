var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('section',[_c('v-row',[_c('v-col',[_c('h2',[_vm._v("Work information")])])],1)],1),_c('v-divider'),(_vm.loading)?_c('section',{staticClass:"py-16"},[_c('app-loading')],1):_c('section',{staticClass:"py-4"},[(_vm.errorMessage.length || _vm.updated)?_c('section',{staticClass:"mb-4"},[(_vm.errorMessage.length)?_c('v-alert',{attrs:{"prominent":"","type":"error","dense":"","close-icon":"my-4","outlined":"","text":"","shaped":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('p',[_vm._v("Error: "+_vm._s(_vm.errorMessage))])])],1)],1):_vm._e(),(_vm.updated)?_c('v-alert',{attrs:{"prominent":"","type":"info","dense":"","close-icon":"my-4","outlined":"","text":"","shaped":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('p',[_vm._v("Updated")])])],1)],1):_vm._e()],1):_vm._e(),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"Hospital/Practice Name","rules":"required_field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Hospital/Practice Name*","outlined":""},model:{value:(_vm.form.organisationName),callback:function ($$v) {_vm.$set(_vm.form, "organisationName", $$v)},expression:"form.organisationName"}})]}}],null,true)}),_c('v-text-field',{attrs:{"label":"Department","outlined":""},model:{value:(_vm.form.department),callback:function ($$v) {_vm.$set(_vm.form, "department", $$v)},expression:"form.department"}}),_c('section',[_c('p',[_vm._v("Required fields are marked with an asterisk *")])]),_c('v-divider',{staticClass:"mb-8"}),(_vm.addressLoading)?_c('section',[_c('v-row',{staticClass:"py-8",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('app-loading-spinner')],1)],1)],1):_c('section',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h3',[_vm._v("Work address")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"accent","dark":""},on:{"click":function($event){_vm.showAddressLookUp = !_vm.showAddressLookUp}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Update address")])])],1)],1),_c('transition',{attrs:{"name":"fade","mode":"in-out"}},[(_vm.showAddressLookUp)?_c('app-address-auto-complete',{staticClass:"my-4",on:{"change":_vm.updateAddress}}):_vm._e()],1),_c('validation-provider',{attrs:{"vid":"streetAddress","rules":"required_if_streetAddress2:streetAddress2|required_if_city:city|required_if_postCode:postCode","name":"1st line of address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"1st line of address","outlined":"","error-messages":errors,"disabled":_vm.disableAddress},model:{value:(_vm.form.streetAddress),callback:function ($$v) {_vm.$set(_vm.form, "streetAddress", $$v)},expression:"form.streetAddress"}})]}}],null,true)}),_c('validation-provider',{attrs:{"vid":"streetAddress2","name":"2nd line of address"}},[_c('v-text-field',{attrs:{"label":"2nd line of address","outlined":"","disabled":_vm.disableAddress},model:{value:(_vm.form.streetAddress2),callback:function ($$v) {_vm.$set(_vm.form, "streetAddress2", $$v)},expression:"form.streetAddress2"}})],1),_c('validation-provider',{attrs:{"vid":"city","rules":"required_if_streetAddress:streetAddress|required_if_streetAddress2:streetAddress2|required_if_postCode:postCode","name":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"City","outlined":"","error-messages":errors,"disabled":_vm.disableAddress},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Post code","vid":"postCode","rules":"required_if_streetAddress:streetAddress|required_if_streetAddress2:streetAddress2|required_if_city:city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Post code","outlined":"","error-messages":errors,"disabled":_vm.disableAddress},model:{value:(_vm.form.postCode),callback:function ($$v) {_vm.$set(_vm.form, "postCode", $$v)},expression:"form.postCode"}})]}}],null,true)})],1),_c('section',[_c('p',[_vm._v(" Your work address will help us verify that you are a UK healthcare professional and improve your customer experience with us ")])])],1)],1),_c('v-divider',{staticClass:"mb-8"}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"rounded":"","type":"submit","color":"accent","large":""}},[_vm._v("Update")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }