


























































































































































































import Vue from "vue";
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import { required, required_if } from "vee-validate/dist/rules";
import {
  AddressesClient,
  ApiException,
  UserAccountUpdateWorkInformationCommand,
} from "@/clients/clients";
import { mapActions, mapGetters } from "vuex";
import AppLoading from "@/components/layout/AppLoading.vue";
import AppAddressAutoComplete from "@/components/addresses/AppAddressAutoComplete.vue";
import AppLoadingSpinner from "@/components/indicators/AppLoadingSpinner.vue";
import { loginRequest } from "@/configs/authConfig";

setInteractionMode("eager");

extend("required_field", {
  ...required,
  message: "{_field_} can not be empty",
});

const addressValidationMessage =
  "{_field_} can not be empty when entering your address";
extend("required_if_streetAddress", {
  ...required_if,
  message: addressValidationMessage,
});
extend("required_if_streetAddress2", {
  ...required_if,
  message: addressValidationMessage,
});
extend("required_if_city", {
  ...required_if,
  message: addressValidationMessage,
});
extend("required_if_postCode", {
  ...required_if,
  message: addressValidationMessage,
});

export default Vue.extend({
  name: "ProfileWorkInformationView",
  components: {
    AppLoadingSpinner,
    AppAddressAutoComplete,
    AppLoading,
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    errorMessage: "",
    updated: false,
    loading: false,
    form: new UserAccountUpdateWorkInformationCommand(),
    disableAddress: true,
    addressLoading: false,
    showAddressLookUp: false,
  }),
  computed: {
    ...mapGetters("UserStore", ["user"]),
  },
  created() {
    if (this.user) {
      const data = new UserAccountUpdateWorkInformationCommand();
      data.init(this.user);
      this.form = Object.assign({}, this.form, data);
    }
  },
  methods: {
    ...mapActions("UserStore", ["updateWorkInformation"]),
    async onSubmit() {
      this.loading = true;
      this.errorMessage = "";
      try {
        await this.updateWorkInformation(this.form);
        this.updated = true;
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
    async updateAddress(val: number | string): Promise<void> {
      this.disableAddress = false;
      if (val === 0) return;
      this.addressLoading = true;
      this.errorMessage = "";
      try {
        const token = await this.$auth.acquireTokenSilent(loginRequest);
        const client = this.$httpClientFactory(
          AddressesClient,
          token.accessToken
        );
        const response = await client.getFullAddress(val.toString());
        if (response.result) {
          this.$set(this.form, "streetAddress", response.result.line1);
          this.$set(this.form, "streetAddress2", response.result.line2);
          this.$set(this.form, "city", response.result.postTown);
          this.$set(this.form, "postCode", response.result.postcode);
        }
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.addressLoading = false;
      }
    },
  },
});
